// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { Hero, text, Button, CallToAction } from '@toggl/ui'
import { colors, fonts, media } from '@toggl/style'
import IndexLayout from 'layouts'
import LayoutGlobal from 'components/LayoutGlobal'
import {
  Navigation,
  hooks,
  Card,
  Section,
  Carousel,
  Icon,
  Accordion,
} from '@toggl/ui'
import type { Job } from 'types'
import type { PageProps } from 'gatsby'
import videoSrc from 'images/uploads/bg-video.mp4'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import ebook from 'images/uploads/Blog-ebook.png'
import blogCarousel from 'images/uploads/Blog-carousel.png'
// import imageSrc1 from '../../static/images/uploads/article1.png'
// import imageSrc2 from '../../static/images/uploads/article2.png'
// import imageSrc3 from '../../static/images/uploads/article3.png'
import megaphone from '../../static/images/uploads/megaphone.png'
import { Link } from 'gatsby'

const sections = [
  {
    title: 'What is the Toggl hiring process like? ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis auctor ante vel semper. Phasellus tellus neque, lobortis eu risus ac, egestas vehicula ante. Vivamus facilisis tellus in lacus ultricies viverra. Nam non luctus sem.',
  },
  {
    title: 'What is the Toggl hiring process like? ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis auctor ante vel semper. Phasellus tellus neque, lobortis eu risus ac, egestas vehicula ante. Vivamus facilisis tellus in lacus ultricies viverra. Nam non luctus sem.',
  },
  {
    title: 'What is the Toggl hiring process like? ',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum facilisis auctor ante vel semper. Phasellus tellus neque, lobortis eu risus ac, egestas vehicula ante. Vivamus facilisis tellus in lacus ultricies viverra. Nam non luctus sem.',
  },
]

const JobPage = ({ pageContext: { data } }: PageProps<null, { data: Job }>) => {
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper
        bgVideo={isDesktop ? videoSrc : ''}
        bgImage={isDesktop ? '' : heroBg}
      >
        {children}

        <Hero.ContentWrapper>
          {isDesktop && (
            <HeroTitle>
              <h4>{data.title}</h4>
              <p>{data.description}</p>
            </HeroTitle>
          )}
        </Hero.ContentWrapper>
      </Hero.Wrapper>
    )
  }

  return (
    <IndexLayout>
      <LayoutGlobal Hero={HeroComponent}>
        <Navigation.BackSocial goBackLink={'/jobs'} title={data.title} />
        <InfoBackground>
          <Info>
            <TextSection>
              <h3>The Role</h3>
              {data.role.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </TextSection>
            {isDesktop && (
              <Table>
                <Cell>
                  <span>Team</span>
                  <span>{data.team}</span>
                </Cell>
                <Cell>
                  <span>Salary</span>
                  <span>{data.salary}</span>
                </Cell>
                <Cell>
                  <span>Location</span>
                  <span>{data.location}</span>
                </Cell>
                <Cell>
                  <span>Duration</span>
                  <span>{data.duration}</span>
                </Cell>
              </Table>
            )}
            <TextSection>
              <h3>About you</h3>
              {data.aboutYou.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </TextSection>
            <Buttons>
              {isDesktop && <Button.Secondary>Are you ready?</Button.Secondary>}
              <Button.Primary>Take the test</Button.Primary>
            </Buttons>
          </Info>
        </InfoBackground>
        <BenefitsSection>
          <Card.OverlapImage image={ebook} alt={'ebook'}>
            <h3>Toggl Benefits</h3>
            <span>We'll make you an offer you can't refuse</span>
            <ul>
              <li>Freedom to choose when and where you work from.</li>
              <li>
                24 days of paid time off a year, plus your local holidays.
              </li>
              <li>
                2 company retreats and 2 team meetups a year (expenses covered)
                for team-building.
              </li>
              <li>Laptop and a €2,000 budget to set up your home office.</li>
              <li>
                Reimbursement for co-working space rent or internet service at
                home.
              </li>
              <li>
                Opportunities to attend trainings, workshops or conferences.
              </li>
              <li>
                Monthly reimbursement for gym membership, massage and other
                things to improve your health.
              </li>
              <li>
                Support for buying a phone, eyeglasses or tools you need for
                doing your best work.
              </li>
            </ul>
          </Card.OverlapImage>
        </BenefitsSection>
        <OverflowHide>
          <Section.Skewed
            color={colors.beige}
            backgroundColor={colors.fadedPurple}
          >
            <div style={{ height: '200px' }}></div>
          </Section.Skewed>
          <Container>
            <Carousel.DiagonAlley>
              <>
                <Picture>
                  <Icon.Smartphone />
                </Picture>
                <TextWrapper>
                  <h4>1. Skills test</h4>
                  <p>
                    Written homework or a live coding task and a chat to
                    understand your experience and expectations.
                  </p>
                </TextWrapper>
              </>
              <>
                <Picture>
                  <Icon.Smartphone />
                </Picture>
                <TextWrapper>
                  <h4>2. Assignment</h4>
                  <p>
                    Written homework or a live coding task and a chat to
                    understand your experience and expectations.
                  </p>
                </TextWrapper>
              </>
              <>
                <Picture>
                  <Icon.Smartphone />
                </Picture>
                <TextWrapper>
                  <h4>3. Test week</h4>
                  <p>
                    Written homework or a live coding task and a chat to
                    understand your experience and expectations.
                  </p>
                </TextWrapper>
              </>
              <>
                <Picture>
                  <Icon.Smartphone />
                </Picture>
                <TextWrapper>
                  <h4>4. Success</h4>
                  <p>
                    Huge success. The cake is a lie, but the cube is forever.
                  </p>
                </TextWrapper>
              </>
            </Carousel.DiagonAlley>
          </Container>
        </OverflowHide>
        <Images>
          <h1>
            Life at <Rotalic>Toggl</Rotalic>
          </h1>
          <Carousel.ImageGallery>
            <>
              <img src={blogCarousel} />
              <p>Caption</p>
            </>
            <>
              <img src={blogCarousel} />
              <p>Caption</p>
            </>
            <>
              <img src={blogCarousel} />
              <p>Caption</p>
            </>
          </Carousel.ImageGallery>
        </Images>
        <BackgroundHalf />
        <Section.Skewed
          color={isDesktop ? colors.fadedPurple : colors.fadedPurpleAlt}
          backgroundColor={
            isDesktop ? colors.fadedPurpleAlt : colors.darkPurple
          }
        >
          <CtaParagraphSectionSecond>
            <CallToAction
              size={2}
              title={<span>From our blog</span>}
              subTitle={
                <span>
                  Real-time syncinc accross <br /> multiple devices.
                </span>
              }
              Cta={() => (
                <Link to={'https://blog.toggl.com'}>
                  <Button.Primary>See all articles</Button.Primary>
                </Link>
              )}
              image={megaphone}
            />
          </CtaParagraphSectionSecond>
        </Section.Skewed>
        <CarouselSection>
          <Carousel.DotMobile>
            <Card.ReadMore
              title={"What we're doing during COVID-19"}
              category={'news'}
              href={'https://toggl.com/blog/track/productivity'}
            />
            <Card.ReadMore
              title={'How to hire remotely.'}
              category={'Online guide'}
              href={'https://toggl.com/blog/track/productivity'}
            />
            <Card.ReadMore
              title={'Long distance relationships.'}
              category={'E-Book'}
              href={'https://toggl.com/blog/track/productivity'}
            />
          </Carousel.DotMobile>
        </CarouselSection>
        <Section.Block background={colors.fadedPurple}>
          <Accordion title="FAQ's" sections={sections} active={0} />
        </Section.Block>
      </LayoutGlobal>
    </IndexLayout>
  )
}

const BackgroundHalf = styled.div`
  display: none;

  ${media.mq[1]} {
    display: inherit;
    background-color: ${colors.fadedPurpleAlt};
    width: 100%;
    height: 300px;
  }
`

const CtaParagraphSection = styled.div`
  display: flex;
  justify-content: center;

  > div {
    max-width: 320px;
    ${media.mq[1]} {
      max-width: 840px;
    }
  }

  ${media.mq[1]} {
    padding: 75px 10px 0 10px;
  }
`

const CtaParagraphSectionSecond = styled(CtaParagraphSection)`
  transform: translateY(-66px);
  ${media.mq[1]} {
    transform: translateY(-160px);
  }
`
const CarouselSection = styled.div`
  display: flex;
  justify-content: center;
  background: ${colors.fadedPurple};

  ${media.mq[1]} {
    padding-bottom: 100px;
  }
`

const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};
`
const Images = styled.div`
  background: ${colors.darkPurple};
  padding: 30px 0;

  h1 {
    ${text.heading1};
    margin-bottom: 70px;
    color: ${(p) => p.theme.primary};
    padding-left: 15%;
  }
  ${media.mq[1]} {
    padding: 0 300px;
    padding-top: 106px;
    padding-bottom: 106px;
    max-height: 600px;
    overflow: visible;
    z-index: 9999;
    position: relative;
  }
`
const HeroTitle = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  h4 {
    ${text.heading3}
    color: ${colors.almostWhite};
    font-family: ${fonts.inter}
  }

  p {
    margin-top: 20px;
    ${text.paragraph2};
    color: ${colors.almostWhite};
  }
`
const Info = styled.div`
  max-width: 710px;
  padding-top: 40px;
  padding-bottom: 70px;
  margin: 0 auto;
  background: ${colors.darkPurple};
`
const InfoBackground = styled.div`
  background: ${colors.darkPurple};
`
const Cell = styled.div`
  span:first-of-type {
    ${text.paragraph4};
    color: ${colors.darkPurple};
  }
`
const Table = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  background: ${colors.beige};
  justify-content: space-between;
  ${text.paragraph2};
  color: ${colors.darkPurple};
  padding: 0 30px;

  ${Cell} {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }
`

const Buttons = styled.div`
  display: flex;
  margin-top: 50px;
  max-width: 400px;
  justify-content: space-between;

  padding: 0 30px;
  ${media.mq[1]} {
    padding: 0;
  }
`

const TextSection = styled.div`
  padding: 0 30px;
  color: ${colors.beige};
  h3 {
    ${text.heading3};
    margin-top: 30px;
  }
  p {
    ${text.paragraph2};
    margin-top: 25px;
  }
  h3 + p {
    margin-top: 50px;
  }

  ${media.mq[1]} {
    padding: 0;
    margin-top: 0;
  }
`
const OverflowHide = styled.div`
  overflow: hidden;
`

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${colors.beige};
  z-index: 900;
  position: relative;
`
const Picture = styled.div`
  text-align: center;
  svg {
    max-width: 309px;
  }
`
const TextWrapper = styled.div`
  overflow: visible;

  padding: 30px;

  ${media.mq[1]} {
    min-height: 300px;
  }
`

const BenefitsSection = styled(Section.Block)`
  background-color: ${colors.fadedPurple};

  ${media.mq[1]} {
    padding-top: 130px;
    padding-bottom: 130px;
  }
`

export default JobPage
